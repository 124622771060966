import {IEnvironment} from './IEnvironment';
import {KeycloakConfig} from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://test.aleph.work/auth',
  realm: 'aleph',
  clientId: 'marketplace'
};

export const environment: IEnvironment = {
  production: true,
  store: 'marketplace',
  idStore: 3,
  itemsPerPage: 10,
  title: 'Aleph - Store',
  apiBase: 'https://test.aleph.work/api',
  urlApp: 'https://test.aleph.work/marketplace/',
  homeCategory: 'HOME',
  siteName: 'Aleph',
  email: 'info@byaleph.com',
  googleTagManagerId: '',
  keycloakConfig,
  multisite: null,
  idpHint: 'keycloak-oidc'

};
